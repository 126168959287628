<!-- 次月续费预测 -->
<template>
  <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="container">
    <div class="title-container">次月续费预测</div>
    <div class="line"></div>
    <!-- 筛选条件 -->
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>客户名称:</span>
        <el-input
          v-model="params.customerName"
          placeholder="请输入内容"
          class="common-screen-input"
          clearable
        ></el-input>
      </div>
      <el-button
        class="common-screen-btn"
        type="primary"
        @click="
          () => {
            commonFun();
          }
        "
        >查 询</el-button
      >
      <el-button class="common-screen-btn" plain @click="() => resetBtn()"
        >重 置</el-button
      >
    </div>
    <div class="gap"></div>
    <!-- 表格 -->
    <div class="common-padding top">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column type="index" :label="'序号'+textNum" width="50"></el-table-column>
      <el-table-column prop="customerName" label="客户名称">
        <template slot-scope="scope">
          <el-button type="text" @click="()=>{ goCustomerPool(scope.row) }">
            {{scope.row.customerName}}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column :label="month+'月续费预测' + (load ? ' ': '')" align="center">
        <el-table-column prop="lastMonthReceivables">
          <template slot="header">
            上月实际应收款<br/><span class="orange">【{{lastMon|| 0}}】</span>
          </template>
          <template slot-scope="scope">
            {{scope.row.lastMonthReceivables?(scope.row.lastMonthReceivables).toFixed(2):'0.00'}}
          </template>
        </el-table-column>
        <el-table-column prop="nowMonthReceivables">
          <template slot="header">
            本月实际应收款<br/><span class="orange">【{{nowMon|| 0}}】</span>
          </template>
          <template slot-scope="scope">
            {{scope.row.nowMonthReceivables?(scope.row.nowMonthReceivables).toFixed(2):'0.00'}}
          </template>
        </el-table-column>
        <el-table-column prop="nextMonthReceivables">
          <template slot="header">
            次月续费预测金额<br/><span class="orange">【{{nextMon|| 0}}】</span>
          </template>
          <template slot-scope="scope">
            {{scope.row.nextMonthReceivables?(scope.row.nextMonthReceivables).toFixed(2):'0.00'}}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="receivables" label="累计应收金额">
        <template slot="header">
            累计应收金额<br/><span class="orange">【{{receivables|| 0}}】</span>
          </template>
          <template slot-scope="scope">
            {{scope.row.receivables?(scope.row.receivables).toFixed(2):'0.00'}}
          </template>
      </el-table-column>
      <el-table-column prop="actualReceivables" label="累计付费金额">
        <template slot="header">
            累计付费金额<br/><span class="orange">【{{actualReceivables || 0}}】</span>
          </template>
          <template slot-scope="scope">
            {{scope.row.actualReceivables?(scope.row.actualReceivables).toFixed(2):'0.00'}}
          </template>
      </el-table-column>
      <el-table-column prop="historicalArrears" label="累计欠款金额">
        <template slot="header">
            累计欠款金额<br/><span class="orange">【{{historicalArrears || 0}}】</span>
          </template>
          <template slot-scope="scope">
            {{scope.row.historicalArrears?(scope.row.historicalArrears).toFixed(2):'0.00'}}
          </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.page"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    </div>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { getFinancialForecast } from "../../service/money.js"
import { mapState } from "vuex";

export default {
  name: "Future",
  components: {},
  props: {},
  data() {
    return {
      loading: false,
      params: {
        customerName: "",
        page: 1,
        pageSize: 10
      },
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      month: 1,
      tableData: [],
      textNum: 0,
      lastMon: '',
      nowMon: "",
      nextMon: "",
      receivables: "",
      actualReceivables: "",
      historicalArrears: "",
      load: true, // 更新表头
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState(["getUserInfo","comId"]),
  },
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getmonths()
    this.getComId()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {
    // 获取comID&&请求列表
    getComId() {
      setTimeout(()=>{
        if(this.comId){
          this.params.companyId = this.comId
        }else{
          this.params.companyId = this.getUserInfo.roles_company.filter((item) => { return item.id != 0; })[0].id
        }
        this.getFinancialForecastList()
      },10)
    },
    getmonths(){
      this.month = new Date().getMonth() + 2 > 12? 1:new Date().getMonth() + 2
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.getFinancialForecastList();
    },
    commonFun() {
      this.params.pageSize = 10;
      this.params.page = 1;
      this.getFinancialForecastList()
    },
    resetBtn() {
      this.params = {
        companyId: this.comId,
        customerName: "",
        page: 1,
        pageSize: 10
      };
      this.getFinancialForecastList()
    },
    async getFinancialForecastList() {
      this.loading = true
      let params = {...this.params}
      const { data } = await getFinancialForecast(params)
      this.tableData = data.list
      this.pagination.total = data.total
      this.pagination.pageSize = data.size
      if(data){
        if(data.lastMonthReceivables){
          this.lastMon = (data.lastMonthReceivables).toFixed(2)
        }else{
          this.lastMon = 0
        }
        if(data.nowMonthReceivables){
          this.nowMon = (data.nowMonthReceivables).toFixed(2)
        }else{
          this.nowMon = 0
        }
        if(data.nextMonthReceivables){
          this.nextMon = (data.nextMonthReceivables).toFixed(2)
        }else{
          this.nextMon = 0
        }
        if(data.receivables){
          this.receivables = (data.receivables).toFixed(2)
        }else{
          this.receivables = 0
        }
        if(data.actualReceivables){
          this.actualReceivables = (data.actualReceivables).toFixed(2)
        }else{
          this.actualReceivables = 0
        }
        if(data.historicalArrears){
          this.historicalArrears = (data.historicalArrears).toFixed(2)
        }else{
          this.historicalArrears = 0
        }
      }
      this.textNum = ""
      this.loading = false
      this.load = !this.load
    },
    goCustomerPool(row){
      window.open(`/customer/customer-pool?row=${JSON.stringify(row)}`)
      // this.$router.push({path: '/customer/customer-pool',query:{row: row}})
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  .title-container {
    color: rgba(16, 16, 16, 100);
    font-size: 24px;
    text-align: left;
  }
  .line {
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #e6e6e6;
  }
  .common-screen-container {
    margin-top: 44px;
    margin-left: 32px;
  }
  .gap {
    height: 16px;
    background-color: #ededed;
  }
  .top {
    padding-top: 32px;
  }
  .el-table {
    // width: 98% !important;
    margin:0 0 16px;
     thead{
      color: #101010 !important;
      .cell{
        color: #101010 !important;
      }
      
    }
  }
  .orange{ 
    color: #f09009;
  }
}
</style>
